import React, {useMemo} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAdminBusinessExpenseReportListQuery} from '@compt/app/services/api/admin-business-expenses-slice';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '@uidotdev/usehooks';
import {useLeanComptTableContext} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {useFilterContext} from '@compt/common/compt-filter-sidebar/filter-context';
import {useFormatFilterQuery} from '../format-query-helper';
import {useNoDataText} from '@compt/common/compt-filter-sidebar/no-data-helper';

// Components
import {LeanComptTable} from '@compt/common/lean-compt-table/lean-compt-table';
import {ComptTableBar} from '@compt/common/compt-table/compt-table-bar';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';

// Types
import {MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE} from '@compt/constants';
import {BusinessExpenseReportFilter} from './review-business-expense-reports-sidebar';

export const ReviewBusinessExpenseReportsTable = () => {
  const navigate = useNavigate();

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const {currentPage, setCurrentPage} = useLeanComptTableContext();
  const {filters, setFilters} = useFilterContext();

  const formatFilterQuery = useFormatFilterQuery(setCurrentPage);
  const paginationQuery = useMemo(
    () => ({
      offset: MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE * Math.max(currentPage - 1, 0),
      page: currentPage,
    }),
    [currentPage],
  );

  const debouncedQueryValues = useDebounce(formatFilterQuery, 300);

  const businessExpenseReportListQuery = useGetAdminBusinessExpenseReportListQuery(
    {
      companyId,
      params: {
        ...debouncedQueryValues,
        ...paginationQuery,
        limit: MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE,
      },
    },
    {skip: !companyId},
  );

  const noDataText = useNoDataText(
    'No expense reports have been added yet',
    'No expense reports were found with the current filters.',
  );

  return (
    <>
      <LeanComptTable
        data={businessExpenseReportListQuery.data?.results ?? []}
        isDataLoading={
          businessExpenseReportListQuery.isLoading || businessExpenseReportListQuery.isFetching
        }
        noDataTitleText={noDataText.title}
        onRowClicked={(row) => {
          navigate(`/review-programs/business-expenses/${row.id}`);
        }}
        totalCount={businessExpenseReportListQuery.data?.count ?? 0}
        itemsPerPage={MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE}
        stickyLastColumn
        showPagination
      >
        <ComptTableBar showColumnFilter>
          <SearchFilter
            key={BusinessExpenseReportFilter.SEARCH}
            currentValues={filters[BusinessExpenseReportFilter.SEARCH]}
            options={[
              {
                id: 1,
                name: 'Business expense title',
                placeholder: 'Search by title',
              },
            ]}
            handleChange={(searchQuery) => {
              setFilters((prevState) => ({
                ...prevState,
                [BusinessExpenseReportFilter.SEARCH]: searchQuery,
              }));
            }}
          />
        </ComptTableBar>
      </LeanComptTable>
    </>
  );
};
