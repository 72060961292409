import React, {useState} from 'react';
import {MonthCalendar} from './month-calendar';

export const CalendarsDisplay = () => {
  const now = new Date();
  // Initialize the left calendar to be one month before the current month
  let initialLeftYear = now.getFullYear();
  let initialLeftMonth = now.getMonth() - 1;
  if (initialLeftMonth < 0) {
    initialLeftMonth = 11;
    initialLeftYear -= 1;
  }

  const [leftYear, setLeftYear] = useState(initialLeftYear);
  const [leftMonth, setLeftMonth] = useState(initialLeftMonth);

  // The right calendar will be the month following the left one
  const rightMonthDate = new Date(leftYear, leftMonth + 1, 1);
  const rightYear = rightMonthDate.getFullYear();
  const rightMonth = rightMonthDate.getMonth();

  // Navigation: update left calendar (right calendar always follows)
  const prevMonth = () => {
    let newMonth = leftMonth - 1;
    let newYear = leftYear;
    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }
    setLeftMonth(newMonth);
    setLeftYear(newYear);
  };

  const nextMonth = () => {
    let newMonth = leftMonth + 1;
    let newYear = leftYear;
    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }
    setLeftMonth(newMonth);
    setLeftYear(newYear);
  };

  return (
    <div className="flex flex-col max-[1550px]:flex-col 2xl:flex-row">
      <MonthCalendar year={leftYear} month={leftMonth} handlePrevMonthSelect={prevMonth} />
      <div className="border-b lg:border-r" />
      <MonthCalendar year={rightYear} month={rightMonth} handleNextMonthSelect={nextMonth} />
    </div>
  );
};
