import React, {useState} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Types
import {DateString} from '@compt/types/common/date-string';
import {DATE_FORMAT_OPTION} from '@compt/utils/date-helpers';

// Components
import {DateQuickSelect} from './date-quick-select';
import {DateRangePickerContextProvider} from './date-range-picker-context';
import {DateRangeFooter} from './date-range-footer';
import {DateRangeDisplay} from './date-range-display';
import {CalendarsDisplay} from './calendars-display';

interface Props {
  label: string;
  initialStartDate?: string;
  initialEndDate?: string;
  dateStringFormat?: DATE_FORMAT_OPTION; // Default is 'yyyy-mm-dd'
  handleChange: (startDate: DateString, endDate: DateString) => void;
}

export const ComptDateRangePicker = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DateRangePickerContextProvider
      initialStartDate={props.initialStartDate ?? ''}
      initialEndDate={props.initialEndDate ?? ''}
    >
      <div className="relative bg-white">
        <DateRangeDisplay label={props.label} setIsOpen={setIsOpen} />
        {isOpen && (
          <div
            className={twMerge(`absolute z-50 flex top-10 lg:right-0 bg-white border border-gray-300
          shadow-md rounded-md transform transition-opacity duration-300
          opacity-${isOpen ? '100' : '0 pointer-events-none'}`)}
          >
            <div className="border-r px-8 py-5 w-40">
              <DateQuickSelect />
            </div>
            <div className="flex flex-col max-h-min">
              <CalendarsDisplay />
              <DateRangeFooter
                handleChange={props.handleChange}
                dateStringFormat={props.dateStringFormat}
                setIsOpen={setIsOpen}
              />
            </div>
          </div>
        )}
      </div>
    </DateRangePickerContextProvider>
  );
};
